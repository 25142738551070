// Este hook personalizado devuelve la fecha actual ajustada a UTC,
// compensando la diferencia horaria de la zona local. Esto es útil
// para generar reportes que requieran la fecha y hora en UTC pero
// formateados según la zona horaria local.

import { useMemo } from 'react';

export const useAdjustedDate = () => {
  const date = useMemo(() => {
    const now = new Date();
    const offset = now.getTimezoneOffset() * 60000;
    return new Date(now.getTime() - offset);
  }, []);

  return date;
};
