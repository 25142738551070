import { discount } from '@constants';
import { StripePeriod } from '@meniu/data';
import clsx from 'clsx';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export const PricesByTier = ({
  setPeriod,
  selectedPeriod,
}: {
  setPeriod: (a: StripePeriod) => void;
  selectedPeriod: StripePeriod;
}) => {
  const { t } = useTranslation();
  return (
    <div className="d-flex justify-content-center mb-4">
      <div className="d-inline-flex justify-content-center align-items-center p-2 bg-light rounded-4 gap-1 w-100">
        <Button
          onClick={() =>
            setPeriod({
              interval: 'month',
              period: 1,
              discount: '',
              discountAmount: 0,
            })
          }
          size="sm"
          className="fw-semibold lh-sm border-0 d-flex flex-column gap-0 align-items-center px-2 px-sm-3 py-2 rounded-3 flex-fill"
          variant={clsx(
            'default',
            selectedPeriod.period === 1 &&
              selectedPeriod.interval === 'month' &&
              'bg-white custom-shadow fw-semibold'
          )}
        >
          <p className="fs-6">{t('Mensual')}</p>{' '}
          <small className="text-muted">{t('Sin ahorro')}</small>
        </Button>
        <Button
          onClick={() =>
            setPeriod({
              interval: 'month',
              period: 6,
              discount: discount.sixMonth,
              discountAmount: discount.semesterDiscountAmount,
            })
          }
          size="sm"
          className="fw-semibold lh-sm border-0 d-flex flex-column gap-0 align-items-center px-2 px-sm-3 py-2 rounded-3 flex-fill"
          variant={clsx(
            'default',
            selectedPeriod.period === 6 &&
              selectedPeriod.interval === 'month' &&
              'bg-white custom-shadow fw-semibold'
          )}
        >
          <p className="fs-6">{t('6 meses')}</p>{' '}
          <small className="text-success fw-semibold">
            {t('Ahorra')} {discount.sixMonth}
          </small>
        </Button>
        <Button
          onClick={() =>
            setPeriod({
              interval: 'year',
              period: 1,
              discount: discount.anual,
              discountAmount: discount.anualDiscountAmount,
            })
          }
          size="sm"
          className="fw-semibold lh-sm border-0 d-flex flex-column gap-0 align-items-center px-2 px-sm-3 py-2 rounded-3 flex-fill"
          variant={clsx(
            'default',
            selectedPeriod.period === 1 &&
              selectedPeriod.interval === 'year' &&
              'bg-white custom-shadow fw-semibold'
          )}
        >
          <p className="fs-6">{t('Anual')}</p>{' '}
          <small className="text-success fw-semibold">
            {t('Ahorra')} {discount.anual}
          </small>
        </Button>
      </div>
    </div>
  );
};
