// Modals
import RegisterModal from './pages/menu/modals/register.modal';
import WarningLoginModal from './pages/menu/modals/warning-login.modal';
import BuyProModal from './pages/plans/modals/buy-pro.modal';
import FeedBackModal from 'shared/feedback.modal';
import NotificationModal from 'shared/notifications.modal';
// import PromoModal from 'shared/modals/Promo.modal';
import RelevantFeatures from 'shared/modals/RelevantFeatures.modal';

export const Modals = () => (
  <>
    <BuyProModal />
    <RegisterModal />
    <WarningLoginModal />
    <FeedBackModal />
    <NotificationModal />
    <RelevantFeatures />
  </>
);
export default Modals;
