import { discount } from '@constants';
import { StripePeriod } from '@meniu/data';
import { useGetPrices } from 'apiClient/api';
import { useEffect, useState } from 'react';

export const useStripePrices = () => {
  const { data: prices, isLoading } = useGetPrices({
    refetchOnWindowFocus: false,
  });

  const [id, setId] = useState('');
  const [period, setPeriod] = useState<StripePeriod>({
    interval: 'year',
    period: 1,
    discount: discount.anual,
    discountAmount: discount.anualDiscountAmount,
  });

  useEffect(() => {
    if (prices) {
      const currentPeriod = prices?.find(
        (d) =>
          d.recurring?.interval === period.interval &&
          d.recurring.interval_count === period.period
      );
      if (currentPeriod) {
        setId(currentPeriod.id);
      }
    }
  }, [period, prices]);

  return { prices, isLoading, period, id, setPeriod, setId };
};
